<template>
  <v-row class="error-row">
    <v-col xl="7" lg="7" md="7" sm="7" cols="12">
      <img
        style="width: 70%; margin: auto; display: block"
        :src="require('../../src/assets/novideocall.svg')"
        alt="Image not found"
      />
    </v-col>
    <v-col xl="5" lg="5" md="5" sm="5" cols="12">
      <v-list-item three-line>
        <v-list-item-content>
          <div
            style="
              border-bottom: 1px solid #80808026;
              padding: 10px;
              padding-right: 8%;
            "
            class="headline mb-4 text-left"
          >
            Error 404
          </div>
          <v-list-item-title class="mb-1 text-left pl-3 mb-5">
            Nothing over here
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>

<style lang="scss">
.error-row {
  height: calc(100vh - 80px);
  align-items: center;
  text-align: center;
}
</style>
